// Generated by Framer (33d5032)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["CP19thW5b", "cHMtfAP6y"];

const serializationHash = "framer-yQm2T"

const variantClassNames = {cHMtfAP6y: "framer-v-oe5t5y", CP19thW5b: "framer-v-ls2ajr"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Thick: "CP19thW5b", Thin: "cHMtfAP6y"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "CP19thW5b"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "CP19thW5b", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-ls2ajr", className, classNames)} data-framer-name={"Thick"} layoutDependency={layoutDependency} layoutId={"CP19thW5b"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} tabIndex={-1} {...addPropertyOverrides({cHMtfAP6y: {"data-framer-name": "Thin"}}, baseVariant, gestureVariant)}><motion.div className={"framer-4jqonn"} layoutDependency={layoutDependency} layoutId={"Evtw_SR4T"} style={{backgroundColor: "var(--token-6555779b-6d9a-4514-84e6-845325a32d37, rgb(0, 0, 0))"}} variants={{cHMtfAP6y: {backgroundColor: "var(--token-1e4de996-fe4b-4667-88fc-284e9577f815, rgba(0, 0, 0, 0.08))"}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-yQm2T.framer-1br1x5g, .framer-yQm2T .framer-1br1x5g { display: block; }", ".framer-yQm2T.framer-ls2ajr { height: 2px; overflow: hidden; position: relative; width: 1040px; }", ".framer-yQm2T .framer-4jqonn { bottom: 0px; flex: none; left: 0px; overflow: hidden; position: absolute; right: 0px; top: 0px; }", ".framer-yQm2T.framer-v-oe5t5y.framer-ls2ajr { height: 1px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 2
 * @framerIntrinsicWidth 1040
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"cHMtfAP6y":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerFSgEsyjdO: React.ComponentType<Props> = withCSS(Component, css, "framer-yQm2T") as typeof Component;
export default FramerFSgEsyjdO;

FramerFSgEsyjdO.displayName = "Component / Divider";

FramerFSgEsyjdO.defaultProps = {height: 2, width: 1040};

addPropertyControls(FramerFSgEsyjdO, {variant: {options: ["CP19thW5b", "cHMtfAP6y"], optionTitles: ["Thick", "Thin"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerFSgEsyjdO, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})